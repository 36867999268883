var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"classroom-students-page"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('intract-smart-list',{staticClass:"mt-4",attrs:{"paginated":"","endpoint":_vm.endpoint,"item-options":{
          avatar: 'image',
          title: 'full_name',
          subtitle: 'username',
          handleClick: (s) =>
            _vm.$router.push({
              name: 'StudentDetails',
              params: { studentId: s.id },
            }),
        }},scopedSlots:_vm._u([{key:"list-item-right",fn:function({ item: student }){return [(student.is_logged_in)?_c('v-chip',{attrs:{"color":"green lighten-4","small":""}},[_vm._v("Logged In")]):_c('v-chip',{attrs:{"color":"red lighten-4","small":""}},[_vm._v("Login Pending")])]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }